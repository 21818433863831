<template>
  <div class="verify-div">
    <FuNav
      :fixed="false"
      :title="$t('ebClaim.tc')"
      :arrow="true"
      :back="() => {$emit('input', false)}"
      percent
    />
    <p>{{ $t('ebClaim.tc1') }}</p>
    <p>{{ $t('ebClaim.tc2') }}</p>
    <p>{{ $t('ebClaim.tc3') }}</p>
    <p>{{ $t('ebClaim.tc4') }}</p>

    <div class="btn-group">
      <div class="verify-btn-group">
        <Button
          class="fu-v2-btn danger block"
          round
          block
          type="danger"
          @click="() => {$emit('input', false)}"
        >{{ $t('ebClaim.Back') }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import FuNav from '@/components/v2/FuNav'
import { Button } from 'vant'
import portal28 from '@/assets/imgs/portal28.png'
export default {
  components: { Button, FuNav },
  props: {},
  data() {
    return {
      portal28,
      active: 1,
      value: '123',
      checked: false,
      showKeyboard: false
    }
  },
  methods: {
    goback() {
      history.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.verify-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
  p {
    margin: 20px 10px;
    line-height: 16px;
    &:first-child {
      font-size: 14px;
      font-family: 'Montserrat-Bold';
    }
  }
}
.btn-group {
  box-shadow: 4px 0px 30px 0px #0000001A;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  .verify-btn-group {
    display: flex;
    gap: 10px;
  }
}
</style>
