<template>
  <Verify />

</template>

<script>
import Verify from '@/views/claim/eb/components/verify/index'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { Verify },
  props: {},
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
